import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import intl from 'react-intl-universal';
import pendingPerUser, { searchPendingPerUser, searchPendingPerUserStatus } from '../../actions/ActionPendingPerUser';
import ReportPage from '../default-v2/ReportPage';
import Breadcrumb from '../../components/Breadcrumbs';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import './styles/report.scss';
import './styles/v2-reports.scss';
import Axios from '../../config/Axios';
import ReportTable from './ReportTable';
import reportsImg from '../../assets/img/reports.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import PaginationMenu from '../default-v2/PaginationMenu';
import { getDocumentTitle } from '../../utils/tools';

const DOCUMENT_STATUS = [];
const oLocalStorage = new EncodedLocalStorage();
class PendingPerUser extends Component {
  constructor(oProps) {
    super(oProps);

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      aPendingPerUser: [],
      bLoading: true,
      cSearch: '',
      cOrderBy: '',
      cOrderType: 'asc',
      aPages: [
        {
          cName: 'Nav.reports',
          cLink: undefined
        },
        {
          cName: 'PendingPerUser.breadcrumb_label',
          cLink: undefined
        }
      ],
      nCurrentPage: 1,
      nTotalPages: 0,
      aFilterUsers: [],
      oFilterReport: {
        aFilterUsers: [],
        cFilterType: localStorage.getItem('filter_type_report') ?? "documents",
        nFilterStatDoc : []
      },
      nItemsPerPage: 50,
      nTotal: 0,
    };
  }

  componentDidMount() {
    const { match } = this.props;

    this.fetchData(match.params.cPendingType);
  }

  fetchData = cDocumentStatus => {
    const { setAlert, clearAlert, match } = this.props;
    const { nCurrentPage, nItemsPerPage, cSearch, cOrderBy, cOrderType, oFilterReport } = this.state;
    let aDocumentStatus = cDocumentStatus;
    if (cDocumentStatus === undefined) {
      aDocumentStatus = match.params.cPendingType;
    }

    const aOrderType = cOrderType ?? 'desc';
    const aOrderBy = cOrderBy ?? 'dUpdated';

    this.setState({ bLoading: true });

    this.getPending(cSearch, aOrderBy, aOrderType, nCurrentPage, nItemsPerPage, aDocumentStatus, oFilterReport)
      .then(aPendingPerUser => {
        this.setState({
          bLoading: false,
          aPendingPerUser: aPendingPerUser.data,
          nTotalPages: aPendingPerUser.last_page,
          nTotal: aPendingPerUser.total
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false, aPendingPerUser: [] });
        setAlert('error', oError.aMsgErrors);
      });

      Axios.get('/user').then(oResponse => {
        this.setState({
          aFilterUsers: oResponse.data.aUsers.map(user => ({ label: user.user_cname, value: user.user_nid }))
        });
      });
  };

  getPending = (cSearch, aOrderBy, aOrderType, nCurrentPage, nItemsPerPage, aDocumentStatus, oFilterReport) => {
    
    const statusMapping = {
      publishing: 3,
      editing: 1,
      review: 2,
      reading: 4,
      all: "", // Caso 'Todos'
    };
    const nFilterDocs = statusMapping[aDocumentStatus] || null;

    if (aDocumentStatus && DOCUMENT_STATUS.includes(aDocumentStatus)) {
      return searchPendingPerUserStatus(
        cSearch,
        aOrderBy,
        aOrderType,
        nCurrentPage,
        nItemsPerPage,
        aDocumentStatus,
        {
          ...oFilterReport,
          nFilterStatDoc: Array.isArray(oFilterReport.nFilterStatDoc) && oFilterReport.nFilterStatDoc.length > 0
          ? oFilterReport.nFilterStatDoc[0]
          : nFilterDocs, 
        }
      );
    }
    return searchPendingPerUser(cSearch, aOrderBy, aOrderType, nCurrentPage, nItemsPerPage, aDocumentStatus, {
      ...oFilterReport,
      nFilterStatDoc: Array.isArray(oFilterReport.nFilterStatDoc) && oFilterReport.nFilterStatDoc.length > 0
      ? oFilterReport.nFilterStatDoc[0]
      : nFilterDocs, 
    });
  };


  handlePageChange = nPageNumber => {
    this.setState({ nCurrentPage: nPageNumber }, this.fetchData);
  };

  handleItemsPerPageChange = eventPagination => {
    const nItemsPerPage = parseInt(eventPagination.target.value, 10);
    this.setState({ nItemsPerPage, nCurrentPage: 1 }, this.fetchData);
  };

  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  renderPendingPerUser = () => {
    const { aPendingPerUser } = this.state;
    const { history } = this.props;
    const cPermissionReturn = (
      <p className="empty-result">
        <strong> {intl.get('PendingPerUser.without_permission')}</strong>
      </p>
    );

    if (aPendingPerUser !== undefined && aPendingPerUser.length === 0) {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('PendingPerUser.empty_result')}</strong>
          </p>
        </>
      );
    }

    const rcmpPendingPerUser = aPendingPerUser
      ? Object.keys(aPendingPerUser).map(cKey => {
        const oInformation = aPendingPerUser[cKey];

        const fnCreateMarkup = htmlString => ({ __html: htmlString });
        const fnRenderPendingActivityDeadline = oInformation => {
          if (oInformation && oInformation.cDeadline !== null) {
            const start = moment(oInformation.cDeadline);
            const end = moment();
            switch (oInformation.cPendingActivity) {
              case 'Atividade Pendente':
                return (
                  <div
                    dangerouslySetInnerHTML={fnCreateMarkup(
                      `${start.isBefore(end)
                        ? intl.get('PendingPerUser.days_overdue', { nDays: end.diff(start, 'days') }) // `${end.diff(start, 'days')} dias de atraso`
                        : intl.get('PendingPerUser.days_to_expire', { nDays: end.diff(start, 'days') * -1 }) // `Faltam ${end.diff(start, 'days') * -1} dias para vencer.`
                      }`
                    )}
                  />
                );
              default:
                return (
                  <div
                    dangerouslySetInnerHTML={fnCreateMarkup(moment(oInformation.cDeadline).format('DD/MM/YYYY'))}
                  />
                );
            }
          }
          return intl.get('PendingPerUser.no_deadline');
        };
        return (
          <tr key={cKey}>
            <td title={oInformation ? oInformation.cUserName : ''} className="col-user-name">
              <span>
                <div
                  dangerouslySetInnerHTML={oInformation ? fnCreateMarkup(oInformation.cUserName) : { __html: '' }}
                />
              </span>
            </td>
            <td title={oInformation ? oInformation.cPendingActivity : ''} className="col-pending-activity">
              <span>
                <div
                  dangerouslySetInnerHTML={
                    oInformation ? fnCreateMarkup(oInformation.cPendingActivity) : { __html: '' }
                  }
                />
              </span>
            </td>
            <td className="col-description">
              <span>
                <div
                  dangerouslySetInnerHTML={oInformation ? fnCreateMarkup(getDocumentTitle(oInformation.cDescription)) : { __html: '' }}
                />
              </span>
            </td>
            <td className="col-deadline">
              {oInformation && oInformation.cDeadline !== null ? (
                <div dangerouslySetInnerHTML={fnCreateMarkup(moment(oInformation.cDeadline).format('DD/MM/YYYY'))} />
              ) : (
                intl.get('PendingPerUser.no_deadline')
              )}
              {/* {fnRenderPendingActivityDeadline(oInformation)} */}
            </td>
          </tr>
        );
      })
      : [];

    return (
      <>
        <ReportTable
          aColumn={[
            { cName: intl.get('PendingPerUser.user'), cOrderBy: 'cUserName', fnHandleClick: this.handleClickTable },
            {
              cName: intl.get('PendingPerUser.pending_activity'),
              cOrderBy: 'cPendingActivity',
              fnHandleClick: this.handleClickTable
            },
            {
              cName: intl.get('PendingPerUser.description'),
              cOrderBy: 'cDescription',
              fnHandleClick: this.handleClickTable
            },
            { cName: intl.get('PendingPerUser.deadline'), cOrderBy: 'cDeadline', fnHandleClick: this.handleClickTable }
          ]}
        >
          {rcmpPendingPerUser}
        </ReportTable>
        {aPendingPerUser === -1 ? cPermissionReturn : null}
      </>
    );
  };

  handleFilter = evt => {
    const { cSearch, cOrderBy, cOrderType, nCurrentPage, nItemsPerPage, oFilterReport } = this.state;
    const { setAlert, clearAlert, match } = this.props;

    const aDocumentStatus = match?.params?.cPendingType ?? '';

    this.getPending(cSearch, cOrderBy, cOrderType, nCurrentPage, nItemsPerPage, aDocumentStatus, oFilterReport)
      .then(aPendingPerUser => {
        this.setState({
          aPendingPerUser: aPendingPerUser.data,
          nTotalPages: aPendingPerUser.last_page,
          nTotal: aPendingPerUser.total
        });
        clearAlert();
      })
      .catch(oError => {
        this.setState({ bLoading: false, aPendingPerUser: -1 });
        setAlert('error', oError.aMsgErrors);
      }).finally(() => {
        this.setState({ bLoading: false });
      });
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        cOrderType: oFilter.cOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  handleFilterReport = (aFilterUsers, cFilterType, nFilterStatDoc) => {
    this.setState(
      {
        oFilterReport: {
          aFilterUsers,
          cFilterType,
          nFilterStatDoc, 
        },
      },
      () => {
        this.handleFilter();
      }
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpFilter = <Filter fnOnType={this.handleNewFilter} />;

    const rcmpCardHeader = <CardHeader history={history} rcmpFilter={rcmpFilter} />;
    return rcmpCardHeader;
  };

  getFilterContent = () => {
    const { aFilterUsers } = this.state;
    const aFilterTypes = [
      {
        label: intl.get("Report.date_filters.documents"),
        value: "documents",
      },
      {
        label: intl.get("Report.date_filters.requests"),
        value: "requests",
      },
      {
        label: intl.get("Report.date_filters.activities"),
        value: "activities",
      },
      {
        label: intl.get("Report.date_filters.requisites"),
        value: "requisites",
      }
    ];

    const aFilterDocuments = [
      {
        label: 'Todos',
        value: '',
      },
      {
        label: intl.get("Report.date_filters.publishing"),
        value: 3,
      },
      {
        label: intl.get("Report.date_filters.editing"),
        value: 1,
      },
      {
        label: intl.get("Report.date_filters.review"),
        value: 2,
      },
      {
        label: intl.get("Report.date_filters.reading"),
        value: 4,
      }
    ];
    return {
      cFilterLabelUsers: intl.get('Report.date_filters.filter_per_user'),
      cFilterLabelTypes: intl.get('Report.date_filters.filter_per_type'),
      cFilterLabelDocument: intl.get('Report.date_filters.filter_per_status_doc'),
      aFilterUsers,
      aFilterTypes,
      aFilterDocuments,
    };
  }

  handleChangeDocuments(elSelectedOption) {
    const options = elSelectedOption?.value ? [elSelectedOption.value] : [];
    this.setState({ nFilterStatDoc: options }, () => {
      this.handleFilter();
    });
  };
  

  render() {
    const { bLoading, aPages, nCurrentPage, nItemsPerPage, nTotalPages, nTotal } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('PendingPerUser.title');
    const oFilterReport = this.getFilterContent();

    return (
      <ReportPage
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        oFilterReport={oFilterReport}
        cTitle={cPageTitle}
        cImage={reportsImg}
        onChangeFilter={this.handleFilterReport}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-report"
        cCurrentSideMenuOption={intl.get('Nav.report')}
      >
        {getAlert()}
        {this.renderPendingPerUser()}
        <PaginationMenu
          nCurrentPage={nCurrentPage}
          nItemsPerPage={nItemsPerPage}
          nTotalPages={nTotalPages}
          nTotal={nTotal}
          handlePageChange={this.handlePageChange}
          handleItemsPerPageChange={this.handleItemsPerPageChange} />
      </ReportPage>
    );
  }
}

export default PendingPerUser;
