import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { toast } from '@stardust-ds/react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import classNames from 'classnames';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import userImg from '../../assets/img/users.svg';
import CardHeader from '../default-v2/CardHeader';
import Axios from '../../config/Axios';
import imgfSelectDown from '../default-v2/img/select-down.svg';
import imgfSelectUp from '../default-v2/img/select-up.svg';
import userConfigImg from '../../assets/img/user-config-blue.svg';
import imgfWarning from '../../assets/img/264-warning-blue.svg';
import { can } from '../../config/Permissions';
import { formatDateToUserTZ } from '../../utils/time';
import oClasses from './MigrateResponsibilityOperational.module.scss';

const MigrateResponsibilityOperational = ({ match }) => {
  const [oConfig, setConfig] = useState(
    JSON.parse(localStorage.getItem('cConfig')) || { cOrderBy: 'cType', cType: 'asc' }
  );
  const [oMigrateModalOptions, setMigrateModalOptions] = useState({ bShow: false });
  const [bIsLoading, setIsLoading] = useState(true);
  const [bHasNextPage, setHasNextPage] = useState(true);
  const [nNextPage, setNextPage] = useState(1);
  const [aResponsibilities, setResponsibilities] = useState([]);
  const [aUsers, setUsers] = useState([]);
  const [aSelectedResps, setSelectedResps] = useState([]);
  const [nShowTollTip, fnSetNShowTollTip] = useState({ id: -1, type: '', value: '' });

  const oCheckAllCbRef = useRef(null);
  const oObserverRef = useRef(null);
  const fnLastItemRef = useCallback((elLastItem) => {
    if (bIsLoading) return;
    if (oObserverRef.current) {
      oObserverRef.current.disconnect();
    }
    if (!bHasNextPage) return;

    oObserverRef.current = new IntersectionObserver(([oEntry]) => {
      if (!oEntry.isIntersecting) return;
      getResponsibilities();
    }, {
      threshold: 0,
      rootMargin: '0px 200px 0px 200px',
    });

    if (elLastItem) oObserverRef.current.observe(elLastItem);
  }, [bIsLoading, bHasNextPage]);

  const nUserId = +match.params.nId;

  // atualiza a lista quando altera a ordenação
  useEffect(() => {
    if (bHasNextPage) {
      getResponsibilities(true);
      setSelectedResps([]);
      uncheckCheckAllCb();
      return;
    }
    const aNewResponsibilities = [...aResponsibilities];
    sortResponsibilities(aNewResponsibilities, oConfig.cOrderBy, oConfig.cType === 'asc');
    setResponsibilities(aNewResponsibilities);
  }, [oConfig.cOrderBy, oConfig.cType]);

  useEffect(() => {
    if (!can('admin')) {
      window.location.href = '/';
      return;
    }
    Axios.get(`/user/all`).then((oResponse) => {
      const aTargetUsers = oResponse.data.aUsers
        .filter(item => item.user_nid !== nUserId)
        .map(oUser => ({ value: oUser.user_nid, label: oUser.user_cname }));
      setUsers(aTargetUsers);
    });

  }, []);

  const getResponsibilities = async (bResetList = false) => {
    try {
      setIsLoading(true);

      const oParams = new URLSearchParams({
        orderBy: oConfig.cOrderBy,
        orderDir: oConfig.cType,
        page: bResetList ? 1 : nNextPage
      });

      const oResponse = await Axios.get(`/op-panel/get-responsibilities/${nUserId}?${oParams.toString()}`);
      /** @type {OpPanelResponsibility[]} */
      const aNewResponsibilities = oResponse.data.aResponsibilities;
      const bHasMore = oResponse.data.bHasNextPage;

      setHasNextPage(bHasMore);
      if (bHasMore) {
        setNextPage((nCurrPage) => bResetList ? 2 : nCurrPage + 1);
      }
      setResponsibilities(bResetList
        ? aNewResponsibilities
        : [...aResponsibilities, ...aNewResponsibilities]
      );
      uncheckCheckAllCb();
    } catch (oError) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const migrateResponsibilities = async (nNewUser, cPassword, bIsMigrateAll) => {

    // otimização simples para reduzir tamanho da requisição e acelerar a migração:
    // quando todas as responsabilidades forem selecionadas, trata como migrar todas
    const bMigrateAll = bIsMigrateAll
      || (!bHasNextPage && aSelectedResps.length === aResponsibilities.length);

    // quando bMigrateAll === true, campo é ignorado no backend
    const aItems = !bMigrateAll
      ? aResponsibilities
        .filter((oResp) => aSelectedResps.includes(oResp.cIndex))
        .map(({ nId, cType }) => ({ nId, cType }))
      : [];

    await Axios.post(`/op-panel/migrate-responsibilities/${nUserId}`, {
      nNewUser,
      cPassword,
      aItems,
      bMigrateAll,
    });
    setMigrateModalOptions(() => ({ bShow: false }));
    getResponsibilities(true);
    setSelectedResps([]);
    toast({
      type: 'success',
      title: intl.get('FolderIndex.success_migration'),
    });
  };

  const updateSorting = (cColumn) => {
    setConfig((oPrevConfig) => {
      const oNewConfig = {
        ...oPrevConfig,
        ...(oConfig.cOrderBy !== cColumn
          ? { cOrderBy: cColumn }
          : { cType: oPrevConfig.cType === 'asc' ? 'desc' : 'asc' }
        ),
      };
      localStorage.setItem('cConfig', JSON.stringify(oNewConfig));
      return oNewConfig;
    });
  };
  const fnShowToollTip = (event, id, ctype, value = null) => {
    const pElement = event.currentTarget
    const firstChild = pElement.firstElementChild

    if (firstChild.scrollWidth > firstChild.clientWidth) {
      fnSetNShowTollTip({ id: id, type: ctype, value: value })
    }
  }
  const handleOpenMigrateModal = (bMigrateAll = false) => {
    setMigrateModalOptions((oPrevOptions) => ({
      ...oPrevOptions,
      bShow: true,
      bMigrateAll,
    }));
  };

  const handleCloseMigrateModal = () => {
    setMigrateModalOptions(() => ({ bShow: false }));
  }

  const handleCheckAll = useCallback((evtClick) => {
    const bIsChecked = evtClick.target.checked;
    const aNewSelectedResps = bIsChecked ? aResponsibilities.map(({ cIndex }) => cIndex) : [];
    setSelectedResps(aNewSelectedResps);
  }, [aResponsibilities, setSelectedResps]);

  const uncheckCheckAllCb = () => {
    const elCheckAllCb = oCheckAllCbRef.current;
    if (elCheckAllCb) elCheckAllCb.checked = false;
  }

  const handleResponsibilityCheck = (evtClick, oResponsibility) => {
    const bIsChecked = evtClick.target.checked;
    const { cIndex } = oResponsibility;
    if (bIsChecked) {
      setSelectedResps((cIdxs) => [...cIdxs, cIndex]);
    } else {
      setSelectedResps((cIdxs) => cIdxs.filter((cIdxs) => cIdxs !== cIndex));
      uncheckCheckAllCb();
    }
  }

  const aPages = [
    { cName: 'user', cLink: '/user' },
    { cName: 'PermissionGroup.operational-panel', cLink: '/dashboard' },
    { cName: 'User.migrate_responsibilities', cLink: undefined }
  ];
  return (
    <Page
      loading={0}
      rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
      cTitle={intl.get('User.migrate_responsibilities')}
      cImage={userImg}
      rcmpCardHeader={<CardHeader bIsItToInsertGoBackBtn={true} windowHistory={true} />}
      className="v2-document"
      cCurrentSideMenuOption={intl.get('Nav.document')}
    >
      <div style={{ height: '64vh', overflowY: 'auto' }}>
        <ResponsibilitiesHeader
          onCheckAll={handleCheckAll}
          onUpdateOrder={updateSorting}
          oConfig={oConfig}
          oCheckAllCbRef={oCheckAllCbRef}
          fnSetNShowTollTip={fnSetNShowTollTip}
          fnShowToollTip={fnShowToollTip}
        />
        <ul style={{ display: 'block' }} className="document-list list-shadow list-view">
          {aResponsibilities?.map((oResponsibility, nIdx) => (
            <ResponsibilityItem
              key={oResponsibility.cIndex}
              ref={nIdx + 1 === aResponsibilities.length ? fnLastItemRef : null}
              oResponsibility={oResponsibility}
              onCheck={handleResponsibilityCheck}
              bIsChecked={aSelectedResps.includes(oResponsibility.cIndex)}
              fnShowToollTip={fnShowToollTip}
              fnSetNShowTollTip={fnSetNShowTollTip}
              nShowTollTip={nShowTollTip}
            />
          ))}
          {bIsLoading && (
            <li className="centered-text">
              <div className="spinner" style={{ width: '50px', height: '50px' }} />
              <br />
              {intl.get('carregando')}
            </li>
          )}
        </ul>
      </div>
      <div className="button-stack">
        <Button
          type="button"
          className="btn break-line-btn"
          onClick={() => handleOpenMigrateModal(true)}
        >
          {intl.get('FolderIndex.migrate')} <br /> {intl.get('User.all')}
        </Button>
        <Button
          type="button"
          className="btn break-line-btn"
          disabled={aSelectedResps.length < 1}
          onClick={() => handleOpenMigrateModal()}
        >
          {intl.get('FolderIndex.migrate')} <br /> {intl.get('FolderIndex.selecteds')}
        </Button>
      </div>
      {oMigrateModalOptions.bShow && (
        <SelectResponsibleModal
          onClose={handleCloseMigrateModal}
          aUsers={aUsers}
          onSubmit={migrateResponsibilities}
          bMigrateAll={oMigrateModalOptions.bMigrateAll}
        />
      )}
    </Page>
  );
}

const ResponsibilitiesHeader = ({
  onCheckAll = () => { },
  onUpdateOrder = () => { },
  oConfig = {},
  oCheckAllCbRef,
  fnShowToollTip = () => { },
  fnSetNShowTollTip = () => { },
}) => {
  const { cOrderBy, cType: cOrderType } = oConfig;
  const cIcon = cOrderType === 'asc' ? imgfSelectUp : imgfSelectDown;

  return (
    <div className={classNames("filterList", "noselect", oClasses['migrate-list'])}>
      <span role="button" tabIndex="0" className="alt-checkbox-v2">
        <input type="checkbox" onChange={onCheckAll} ref={oCheckAllCbRef} />
      </span>{' '}
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('cName')}
        className={classNames('filterByName-v2', cOrderBy === 'cName' && 'active')}
      >
        {intl.get('name')}
        {cOrderBy === 'cName' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('cType')}
        className={classNames('filterByPublish', cOrderBy === 'cType' && 'active')}
      >
        {intl.get('LinkDocument.tipo')}
        {cOrderBy === 'cType' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('cLocation')}
        className={classNames('filterByDue', cOrderBy === 'cLocation' && 'active')}
      >
        {intl.get('Dashboard.place')}
        {cOrderBy === 'cLocation' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}{' '}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('bIsSingleResponsible')}
        className={classNames('filterByEnvelope', cOrderBy === 'bIsSingleResponsible' && 'active')}
      >
        {intl.get('PermissionGroup.sole_responsible')}
        {cOrderBy === 'bIsSingleResponsible' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}{' '}
      </span>
      <span
        role="button"
        tabIndex="0"
        onClick={() => onUpdateOrder('cDueDate')}
        className={classNames('filterByDue', cOrderBy === 'cDueDate' && 'active')}
      >
        {intl.get('FolderIndex.finish_at')}
        {cOrderBy === 'cDueDate' && (
          <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
        )}{' '}
      </span>
    </div>
  );
};

const ResponsibilityItem = forwardRef(({
  oResponsibility,
  onCheck = () => { },
  bIsChecked,
  fnShowToollTip = () => { },
  fnSetNShowTollTip: fnSetOShowTollTip = () => { },
  nShowTollTip: oShowTollTip
}, ref) => {

  const getDisplayName = (oItem) => {
    if (oItem.cType === 'activity') {
      return `${oItem.nId} - ${oItem.cName}`
    }
    return oItem.cName;
  }

  const getIsOnlyResponsibleHint = (bIsSingleResponsible) => {
    return intl.get(bIsSingleResponsible
      ? 'MigrationBoard.unique_migration_operational_hint'
      : 'MigrationBoard.not_unique_migration_operational_hint'
    );
  }

  const cDisplayName = getDisplayName(oResponsibility);
  const cOnlyResponsibleHint = getIsOnlyResponsibleHint(oResponsibility.bIsSingleResponsible);

  return (
    <li role="presentation" ref={ref}>
      <h2 className="alt-checkbox-v2 ">
        <input
          type="checkbox"
          onChange={(evtChange) => onCheck(evtChange, oResponsibility)}
          checked={bIsChecked}
        />
      </h2>

      <h2 className="filterByName-v2" title={cDisplayName}>
        {cDisplayName}
      </h2>

      <div 
        className='filterByPublishContainer' 
        onMouseEnter={(e) => fnShowToollTip(e, oResponsibility.nId, oResponsibility.cType)}
        onMouseLeave={() => fnSetOShowTollTip({})}
      >
        <p className="filterByPublish"
          
        >{intl.get(`PermissionGroup.${oResponsibility.cType}`)}
        </p>
        {oShowTollTip.id === oResponsibility.nId && oShowTollTip.type === oResponsibility.cType && oShowTollTip.value === null && <p className="toolltip">{intl.get(`PermissionGroup.${oResponsibility.cType}`)}</p>}
      </div>
      <div
        className='filterByDueContainer'
        onMouseEnter={(e) => fnShowToollTip(e, oResponsibility.nId, oResponsibility.cType, oResponsibility.cLocation)}
        onMouseLeave={() => fnSetOShowTollTip({})}
      >
        <p className="filterByDue">{oResponsibility.cLocation}</p>
        {oShowTollTip.id === oResponsibility.nId && oShowTollTip.type === oResponsibility.cType && oResponsibility.cLocation === oShowTollTip.value && <p className="toolltip" style={{ right: "36%" }}>{oResponsibility.cLocation}</p>}
      </div>



      <p className="filterByPublish" title={cOnlyResponsibleHint}>
        {(oResponsibility.bIsSingleResponsible ? intl.get('sim') : intl.get('nao'))}
      </p>

      <p className="filterByPublish">
        {oResponsibility.cDueDate
          ? formatDateToUserTZ(oResponsibility.cDueDate, 'LL', 'UTC')
          : intl.get('FolderIndex.no_due')}
      </p>
    </li>
  );
});

export const SelectResponsibleModal = ({
  onClose = () => { },
  aUsers = [],
  onSubmit = () => { },
  bMigrateAll = false,
}) => {

  const [nSelectedUserId, setSelectedUserId] = useState(null);
  const [cPassword, setPassword] = useState('');
  const [cErrorMsg, setErrorMsg] = useState('');
  const [bShowConfirmationModal, setShowConfirmationModal] = useState(false);
  const [bIsSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const closeOnEsc = (evtKeyDown) => {
      if (evtKeyDown.key !== 'Escape') return;
      if (bShowConfirmationModal) {
        return handleCloseConfirmationModal();
      }
      onClose();
    };

    window.addEventListener('keydown', closeOnEsc);

    return () => {
      window.removeEventListener('keydown', closeOnEsc);
    };

  }, [bShowConfirmationModal, onClose]);

  const handleUserChange = useCallback((oOption) => {
    setSelectedUserId(oOption ? oOption.value : null);
  }, []);

  const handlePasswordChange = useCallback((evtChange) => {
    setPassword(evtChange.target.value);
  }, []);

  const handleCloseConfirmationModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const handleSubmit = useCallback(async (bConfirmed = false) => {
    if (bIsSubmitting) return;

    if (bMigrateAll && !bConfirmed) {
      setShowConfirmationModal(true);
      return;
    }

    setErrorMsg('');
    setShowConfirmationModal(false);

    try {
      setIsSubmitting(true);
      await onSubmit(nSelectedUserId, cPassword, bMigrateAll);
    } catch (oError) {
      setPassword('');
      setErrorMsg(oError.response.data.cMessage);
      setIsSubmitting(false);
    }
  }, [nSelectedUserId, cPassword, bMigrateAll, onSubmit, bIsSubmitting]);

  const bDisableSubmit = !nSelectedUserId || !cPassword || bIsSubmitting;

  return (
    <div className="docusign-modal-overlay">
      <div className="docusign-modal">
        <div className="docusign-header-modal">
          <h3>{intl.get('User.migrate_responsibilities')}</h3>
          <span className="docusign-close" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className="docusign-modal-body">
          <div className="icon-container">
            <img className="block-title-icon" src={userConfigImg} />
          </div>
          {bMigrateAll && (
            <div className="docusign-modal-text" style={{ color: 'red' }}>
              {'*'}{intl.get('FolderIndex.migrate_responsibilities_all')}
            </div>
          )}

          <div className="docusign-modal-text">
            <div className="margin-bottom-30">
              <form
                autoComplete="off"
                onSubmit={(evtSubmit) => evtSubmit.preventDefault()}
              >
                <label style={{ fontWeight: '700' }}>
                  {intl.get(bMigrateAll ? 'FolderIndex.new_responsible_all' : 'FolderIndex.new_responsible')}
                </label>
                <label className="split1 colspan ">
                  <Select
                    className="selectCustom select"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => intl.get('sem_opcoes')}
                    placeholder={`${intl.get('selecione')}...`}
                    isClearable
                    isSearchable
                    options={aUsers}
                    maxMenuHeight={130}
                    defaultValue={nSelectedUserId}
                    onChange={handleUserChange}
                    autoFocus
                    required
                  />
                </label>
              </form>
            </div>
            <div>
              <label style={{ fontWeight: '700' }}>
                {intl.get('FolderIndex.digit_password')}
              </label>
              <div className="input-select ">
                <input
                  type="password"
                  value={cPassword}
                  onChange={handlePasswordChange}
                />
              </div>
              {cErrorMsg && <p style={{ color: '#FF3541', fontSize: '12px' }}>{cErrorMsg}</p>}
            </div>
          </div>
        </div>
        <div className="button-container">
          <button type="button" className="btn alternate" onClick={onClose}>
            {intl.get('cancel')}
          </button>
          <button type="button" className={classNames('btn', bDisableSubmit && 'disabled')} onClick={() => handleSubmit(false)} disabled={bDisableSubmit}>
            {intl.get('FolderIndex.migrate')}{' '}
          </button>
        </div>
      </div>
      {bShowConfirmationModal && (
        <div className="docusign-modal-overlay">
          <div className="docusign-modal">
            <div className="docusign-header-modal">
              <h3>{intl.get('User.migrate_responsibilities')}</h3>
              {/* <span className="docusign-close" onClick={handleCloseConfirmationModal}>
                &times;
              </span> */}
            </div>
            <div className="docusign-modal-body">
              <div className="icon-container">
                <img className="block-title-v2" src={imgfWarning} />
              </div>
              <div className="docusign-modal-text align-center" style={{ fontWeight: '700' }}>
                {intl.get('FolderIndex.migrate_responsibilities_all_confirm')}
              </div>
            </div>
            <div className="button-container align-center">
              <button type="submit" className="btn" autoFocus onClick={handleCloseConfirmationModal}>
                {intl.get('cancelar')}
              </button>
              <button type="button" className="btn alert" onClick={() => handleSubmit(true)}>
                {intl.get('confirmar')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
};

/**
 * Ordena e retorna a lista de responsabilidades. **Modifica o array passado como parâmetro.**
 * @param {OpPanelResponsibility[]} aResponsibilities
 * @param {"cName"|"cType"|"cDueDate"|"cLocation"|"bIsSingleResponsible"} cColumn
 * @param {boolean} bAscending
 */
const sortResponsibilities = (aResponsibilities, cColumn, bAscending) => {
  const nSign = bAscending ? 1 : -1;

  if (cColumn === 'bIsSingleResponsible') {
    return aResponsibilities.sort((a, b) => nSign * (Number(a[cColumn]) - Number(b[cColumn])));
  }

  return aResponsibilities.sort((a, b) => nSign * (a[cColumn] ?? '').localeCompare(b[cColumn] ?? ''));
};

/**
 * @typedef {object} OpPanelResponsibility
 *
 * @property {number} nId
 * @property {string} cIndex valor único para identificar a responsabilidade,
 *                           com formato `${cType}-${nId}`.
 * @property {string} cType
 * @property {string} cName
 * @property {string?} cDueDate
 * @property {string} cLocation
 * @property {boolean} bIsSingleResponsible
 */

export default MigrateResponsibilityOperational;
