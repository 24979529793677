import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Page from '../../default/Page';
import Axios from '../../../config/Axios';
import { Alert } from '../../../components/Alert';
import Dropzone from 'react-dropzone';
import '../../evidence/styles/evidences.scss';

class UploadFiles extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      oFiles: [],
      cProgress: 0,
      iSend: false,
      rcmpAlert: null
    };
  }

  componentDidMount = () => {
    const { oRequisite } = this.props;
    this.setState({
      oRequisite,
      bLoading: false
    });
  };

  onDrop = acceptedFiles => {
    this.setState({
      oFiles: acceptedFiles
    });
  };

  onDropRejected = rej => {
    this.setState({
      rcmpAlertMsg: (
        <Alert type="error" isOpen onCloseAlert={() => { }}>
          {Object.keys(rej[0].errors).map((cKey, i) => (
            <>
              {rej[0].errors[cKey].code === 'file-invalid-type' && <p> - {intl.get("UploadFiles.formato_invalido")}</p>}
              {rej[0].errors[cKey].code === 'file-too-large' && <p> - {intl.get("UploadFiles.limite_de_tamanho")}</p>}
            </>
          ))}
        </Alert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  //TODO: Verificar para centralizar a função de envio de arquivos
  sendFiles = async () => {
    const { oFiles, oRequisite } = this.state;
    const { onSuccess, oAudit } = this.props;
    const oAuditEvidence = oRequisite.audit ?? oAudit;

    this.hideAlert();
    this.setState({
        cProgress: 0,
        iSend: true,
    });

    for (const oFile of oFiles) {
        // Verifica se o arquivo não está vazio
        if (oFile.size === 0) {
            this.setState({
                rcmpAlert: (
                    <Alert type="error" isOpen onCloseAlert={this.hideAlert}>
                        {intl.get('UploadFiles.arquivo_vazio', { cNomeAquivo: oFile.name })}
                    </Alert>
                ),
            });
            continue;
        }

        const formData = new FormData();
        formData.append('arev_cattachment', oFile);
        formData.append('arq_nid', oAuditEvidence.arq_nid);
        formData.append('norm_nid', oRequisite.norm_nid);
        formData.append('req_nid', oRequisite.req_nid);
        formData.append('arev_etype', 'attachment');
        formData.append('arev_ntype', 1);
        formData.append('arev_bisactive', 1);
        formData.append('arev_ctitle', oFile.name);

        try {
            const oResponse = await Axios.post(`audit-evidence/${oAuditEvidence.audi_nid}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (oResponse.status === 200) {
                const { cProgress } = this.state;
                this.setState(oPrevState => ({
                    cProgress: oPrevState.cProgress + 1,
                }));

                if (cProgress + 1 === oFiles.length) {
                    setTimeout(() => {
                        onSuccess(intl.get('LinkDocument.requisito_incluido_com_sucesso'));
                    }, 2000);
                }
            }
        } catch (error) {
          const errorMessage =error.response?.data?.errors?.[0] || intl.get("Erro inesperado");
          this.setState({
            rcmpAlert: (
              <Alert type="error" isOpen onCloseAlert={this.hideAlert}>
                {errorMessage}
              </Alert>
            ),
            iSend: false,
          });
        }
    }

    this.setState({
        iSend: false,
    });
  };



  clearSelection = () => {
    this.setState({
      oFiles: [],
      iSend: false,
      cProgress: 0
    });
  };

  removeFile = oFile => {
    this.setState(oPrevState => ({
      oFiles: oPrevState.oFiles.filter(oFilterFile => oFilterFile.path !== oFile.path)
    }));
  };

  render() {
    const { bLoading, oFiles, cProgress, iSend, rcmpAlert } = this.state;
    const { closeModal } = this.props;
    return (
      <Page loading={bLoading ? 1 : 0}>
        {rcmpAlert}
        {oFiles.length === 0 && (
          <Dropzone
            accept="image/*, text/csv, application/vnd.oasis.opendocument.spreadsheet, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, text/rtf, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/mspowerpoint, application/powerpoint, application/vnd.ms-powerpoint, application/x-mspowerpoint"
            maxSize={10485760}
            onDrop={this.onDrop}
            onDropRejected={this.onDropRejected}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <div className={'drag-drop-zone'} {...getRootProps()}>
                <input {...getInputProps()} />
                {!isDragActive && (
                  <p>
                    {intl.get("UploadFiles.arraste_arquivo")} <br /> {intl.get("UploadFiles.ou")}
                  </p>
                )}
                {isDragActive && !isDragReject && <p>{intl.get("UploadFiles.solte_para_upload")}</p>}
                {isDragReject && <p>{intl.get("UploadFiles.tipo_invalido")}</p>}

                <button className="btn primary">{intl.get("UploadFiles.faca_upload")}</button>
              </div>
            )}
          </Dropzone>
        )}

        {oFiles.length > 0 && (
          <div className="renderFiles">
            <ul className="list-group mt-2">
              {oFiles.map((oFile, index) => (
                <li key={index} className="list-group-item list-group-item-success">
                  <i onClick={() => this.removeFile(oFile)} className="icon icon-close" />
                  <span>
                    <i
                      className={`icon ${String(oFile.type).indexOf('image') === -1 ? 'icon-document' : 'icon-image'}`}
                    />
                    {oFile.name}
                  </span>
                </li>
              ))}
            </ul>

            <div
              className="progress-evidences"
              style={{
                width: `${(100 / (oFiles.length || 100)) * cProgress}%`
              }}
            >{`${(100 / (oFiles.length || 100)) * cProgress == 100
              ? intl.get("UploadFiles.concluido")
              : ((100 / (oFiles.length || 100)) * cProgress).toFixed(2) + '%'
              }`}</div>

            <div className="button-stack">
              <button
                type="button"
                onClick={() => closeModal()}
                className="btn alternate back btn btn-outline-secondary"
              >
                {intl.get('cancelar')}
              </button>

              <button
                disabled={iSend}
                onClick={() => this.sendFiles()}
                className={`btn primary ${iSend ? 'disabled' : ''}`}
              >
                {intl.get('salvar')}
              </button>
            </div>
          </div>
        )}
      </Page>
    );
  }
}

export default UploadFiles;
