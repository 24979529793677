/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import AltCheckbox from '../../components/AltCheckbox';
import Filter from './components/Filter';
import CardHeader from '../default-v2/CardHeader';
import { Alert } from '../../components/Alert';
import './styles/qualitypanelconsultant.scss';
import { textSearchFormat } from '../norm/RequisiteIndex';
import logo from '../../assets/img/logo-acreditese.svg';
import Masonry from 'react-masonry-component';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();
class QualityPanelIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      companies: [],
      filteredCompanies: [],
      activeSmart: false,
      filterBy: 'name',
      showInactives: false,
      bShowCompletedCertifications: false,
      filtredNorms: [],
      aPages: [{ cName: 'QualityPanelConsultant.titulo', cLink: undefined }],
      oLoadNorm: '',
      rcmpAlert: null
    };

    this.gridRef = React.createRef();

    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setAlert = (cType, cMessage) => {
    this.setState({
      rcmpAlert: (
        <Alert type={cType} isOpen onCloseAlert={() => { }}>
          {cMessage}
        </Alert>
      )
    });
  };

  componentDidMount = () => {
    const { history, location } = this.props;
    const initial = new URLSearchParams(location.search).get('initial');

    Axios.get('/consultant')
      .then(res => {
        if (!initial || res.data.length > 1) {
          this.setState({
            companies: res.data.map(obj => ({
              ...obj
            })),
            loading: false
          });
        } else {
          history.push(`/quality-panel/`);
        }
      })
      .catch(oError => {
        if (oError.response.data) {
          this.setAlert('error', oError.response.data);
        }
      });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  msgSucesso = msg => {
    this.hideAlert();
    this.componentDidMount();

    this.setAlert('success', msg);
  };

  validateCustomer = (oCompanie, nNormId, bAudit) => {
    let cCustId = oLocalStorage.get('nCustId');
    if (cCustId && oCompanie.cust_nid !== parseInt(cCustId)) {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            closeOnClickOutside={false}
            showCancel
            confirmBtnText={intl.get('confirmar')}
            cancelBtnText={intl.get('cancelar')}
            cancelBtnBsStyle="danger"
            title={intl.get('QualityPanelConsultant.confirm_company_change_title')}
            onConfirm={() => {
              this.changeCustomer(oCompanie, nNormId, bAudit);
              this.hideAlert();
            }}
            onCancel={this.hideAlert}
          >
            {intl.getHTML('QualityPanelConsultant.confirm_company_change_msg', { cName: oCompanie.cust_cfantasyname })}
          </SweetAlert>
        )
      });
      return;
    }
    this.changeCustomer(oCompanie, nNormId, bAudit);
  };

  changeCustomer = (oCompanie, nNormId, bAudit) => {
    const { history } = this.props;

    Axios.get(`customer/connect/${oCompanie.cust_nid}`)
      .then(oRes => {
        localStorage.setItem('cAccessToken', oRes.data.cAccessToken);
        localStorage.setItem('cStorageToken', oRes.data.cStorageToken);
        oLocalStorage.decodeToken();
        sessionStorage.setItem('nCustId', oCompanie.cust_nid);
        sessionStorage.setItem('cOldCompanyName', oCompanie.cust_cfantasyname);
        sessionStorage.setItem('cOldCompanyCNPJ', oCompanie.cust_ccnpj);

        Axios.defaults.headers.common['nCustId'] = oCompanie.cust_nid;

        if (bAudit) history.push(`/requisite/${nNormId}`);
        else history.push(`/quality-panel/`);
      })
      .catch(oError => {
        this.setAlert('error', oError.response.data);
      });
  };

  handleChangeState = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  handleChangeFilter = (e, oFilter) => {
    this.setState(
      {
        filterSearch: oFilter.cText
      },
      () => {
        this.applyFilter();
      }
    );
  };

  filterByName = norm => {
    const { filterSearch } = this.state;
    const normName = norm && norm.norm_cname ? norm.norm_cname : '';
    return textSearchFormat(normName).includes(String(filterSearch).toLowerCase());
  };

  filterByInitial = norm => {
    const { filterSearch } = this.state;
    return textSearchFormat(norm.norm_cinitials).includes(String(filterSearch).toLowerCase());
  };

  filterByCompanyName = company => {
    const { filterSearch } = this.state;
    return textSearchFormat(company.cust_cfantasyname).includes(String(filterSearch).toLowerCase());
  };

  checkAllFilters = company => {
    if (this.filterByCompanyName(company)) return company;

    company.colNorms = company.colNorms.filter(norm => this.filterByName(norm) || this.filterByInitial(norm));
    return company.colNorms.length ? company : null;
  };

  applyFilter = () => {
    const { filterSearch, companies } = this.state;

    const aFiltered = JSON.parse(JSON.stringify(companies))
      .map(company => this.checkAllFilters(company, filterSearch))
      .filter(company => company !== null);

    this.setState({
      filteredCompanies: aFiltered.length ? aFiltered : []
    });
  };

  generateCardHeader = () => {
    const { bShowCompletedCertifications } = this.state;
    const { history } = this.props;

    const aCheckboxes = [];
    const rcmpCompletedCertificationsSwitch = (
      <AltCheckbox
        key="checkbox-show-completed"
        onChange={this.handleChangeState}
        title={intl.get('QualityPanelConsultant.certificacoes_concluidas')}
        label={intl.get('QualityPanelConsultant.certificacoes_concluidas')}
        name="bShowCompletedCertifications"
        localeLabel="end"
        checked={bShowCompletedCertifications}
      />
    );
    aCheckboxes.push(rcmpCompletedCertificationsSwitch);

    const rcmpFilter = <Filter fnOnType={this.handleChangeFilter} bIsOrderActive={false} aCheckboxes={aCheckboxes} />;

    const rcmpCardHeader = <CardHeader rcmpFilter={rcmpFilter} bIsItToInsertGoBackBtn={true} history={history} />;
    return rcmpCardHeader;
  };

  render() {
    const { rcmpAlert } = this.state;
    return (
      <div className="v2-page-content consultant-panel">
        <img src={logo} className="logo" alt="logo" />
        <div className="card v2-page-card">
          {this.generateCardHeader()}
          <div className="card-body">
            {rcmpAlert}
            {this.renderPage()}
          </div>
        </div>
      </div>
    );
  }

  renderPage() {
    const { companies, filteredCompanies, bShowCompletedCertifications } = this.state;

    return (
      <>
        <div className="container-cards">
          <Masonry className="grid" elementType="ul">
            {(filteredCompanies.length ? filteredCompanies : companies).map((companie, companyIndex) => (
              <li key={`company-${companyIndex}`} className="item">
                <div
                  className={`content ${companie.cust_status === 'inactive' ? 'inactive-block' : ''}`}
                  onClick={() => {
                    if (companie.cust_status !== 'inactive') {
                      this.validateCustomer(companie, 0, false);
                    } 
                  }}
                >
                  <div className="header">
                    <h2>{companie.cust_cfantasyname}</h2>
                  </div>
                  <div className="contentRole">
                  {companie.cust_status === 'inactive' && (
                    <span className="inactive-status-quality">Cancelado desde: {companie.cust_dinactive}</span>
                  )}
                    {companie.colNorms
                      .filter(oNorm =>
                        bShowCompletedCertifications ? oNorm.norm_biscertified : oNorm.norm_biscertified === 0
                      )
                      .map((oNorm, normIndex) => (
                        <div
                          key={`company-${companyIndex}-norm-${normIndex}`}
                          onClick={() => {
                            if (companie.cust_status !== 'inactive') {
                              this.validateCustomer(companie, oNorm.norm_nid, true);
                            } else {
                              this.setAlert('error', `Cliente está inativo desde ${companie.cust_dinactive}`);
                            }
                          }}
                          className="norm"
                        >
                          <h5>
                            {intl.get('QualityPanelConsultant.certificacao', { cNormInitials: oNorm.norm_cinitials })}
                          </h5>
                          <div className="containerBar">
                            <div className="progressBar">
                              <span
                                style={{
                                  width: `${oNorm.norm_nprogress}%`
                                }}
                                className="fill"
                              />
                            </div>

                            <span className="tooltipNorma">
                              <p>
                                {intl.get('RequisiteIndex.status_concluido')} : {oNorm.finishedRequisites}{' '}
                              </p>
                              <p>
                                {intl.get('RequisiteIndex.status_nao_aplica')} : {oNorm.notAppliedRequisites}{' '}
                              </p>
                              <p>
                                {intl.get('RequisiteIndex.status_pendente')} : {oNorm.pendingRequisites}{' '}
                              </p>
                            </span>

                            <span className="percent">{`${oNorm.norm_nprogress}%`}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </li>
            ))}
          </Masonry>
        </div>
      </>
    );
  }

}

export default QualityPanelIndex;
