import React, { useEffect, useState } from 'react';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import intl from 'react-intl-universal';
import CardHeader from '../default-v2/CardHeader';
import sanitizeHtml from 'sanitize-html';
import cManagementImg from '../../assets/img/management.svg';

import imgfNoPicLighter from '../../assets/img/nopic_lighter.svg';

import './styles/auditinteractions.scss';
import { formatDateToUserTZ } from '../../utils/time';

const AuditInteractions = ({ history, match }) => {
  const [interactions, setInteractions] = useState([]);
  const [load, setLoad] = useState(true);
  const [audit, setAudit] = useState([]);
  const [aPages] = useState([{ cName: 'DashboardAudit.titulo', cLink: '/audit-management' }]);

  const nAuditId = match.params.nAuditId;

  useEffect(() => {
    Promise.all([
      Axios.get(`audit/interactions/${nAuditId}`).then(res => {
        setInteractions(res.data);
      }),
      Axios.get(`audit/${nAuditId}`).then(res => {
        setAudit(res.data.oAudit);
      })
    ]).then(() => {
      setLoad(false);
    });
  }, []);

  const renderBreadcrumbs = () => {
    return (
      <div className="breadcrumbs-wrapper" style={{ fontSize: '14px' }}>
        <div className="inner-bread">
          <Breadcrumb
            bLastLink
            aPages={[
              ...aPages,
              {
                cName: audit.norm.norm_cinitials,
                cLink: `/requisite-audit/${audit.audi_nid}`
              }
            ]}
          />
        </div>
        <span>{'>'}</span>
        <span>{intl.get('AuditInteractions.interacoes')}</span>
      </div>
    );
  };

  const generateCardHeader = () => {
    return <CardHeader bIsItToInsertGoBackBtn={true} history={history} />;
  };

  return (
    !load && (
      <Page
        loading={load ? 1 : 0}
        rcmpBreadcrumb={renderBreadcrumbs()}
        rcmpCardHeader={generateCardHeader()}
        cImage={cManagementImg}
        cTitle={intl.get('AuditInteractions.titulo')}
        cDescription={audit.norm ? audit.norm.norm_cinitials : ''}
        cCurrentSideMenuOption={intl.get('Nav.audit_management')}
        className="v2-audit-interactions"
      >
        {interactions.length ? (
          <ul className="interactions">
            {interactions.map((oInteraction, nIndex) => (
              <li key={`i-${nIndex}`}>
                <div className="title-wrapper">
                  <span className="avatar">
                  <img
                    src={oInteraction.user?.user_cavatar || imgfNoPicLighter}
                    alt={oInteraction.user?.user_cname || intl.get('AuditInteractions.sem_nome')}
                  />
                  </span>
                  <p className="name">{oInteraction.user?.user_cname || intl.get('AuditInteractions.sem_nome')}</p>
                </div>

                <div className="message">
                  <b>
                    {oInteraction.arin_ctext
                      ? intl.get('AuditInteractions.requisito')
                      : intl.get('AuditInteractions.auditoria')}
                  </b>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(oInteraction?.arin_ctext ?? oInteraction?.auin_ccontent ?? '')
                    }}
                  />
                </div>

                <p className="date">
                  {formatDateToUserTZ(
                    oInteraction.arin_dcreated || oInteraction.auin_dcreated
                  )}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="empty-folder">
            <strong>{intl.get('AuditInteractions.sem_interacoes')}</strong>
          </p>
        )}
      </Page>
    )
  );
};

export default AuditInteractions;
