import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import { decodeToken } from 'react-jwt';
import cLoadingImage from '../../assets/img/preloader-black.gif';
import Header from './Header';
import classNames from 'classnames';
import SideMenu from './SideMenu';
import Axios from '../../config/Axios';
import '../../assets/scss/variables.scss';
import './styles/font.scss';
import './styles/page.scss';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import { signIn } from '../../config/Authentication';
import Select from 'react-select';
import "./styles/index.scss";

class ReportPage extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      nUserId: oLocalStorage.get('nUserId'),
      oUser: {
        user_cname: '',
        user_cavatar: null,
        customer: {
          cust_nquotaused: null,
          nDiskSpace: null
        }
      },
      currentTypeFilterShortcut: null,
      bLoading: true,
      bIsMenuOpened: false,
      bIsTransitionEnd: false,
      bSyncLoading: true,
      aSelectedUsers: [],
      aSelectedDocuments: [],
    };
    this.bIsSetFooter = true;

  }


  componentDidMount() {

    this.setState({
      currentTypeFilterShortcut: localStorage.getItem('filter_type_report') ?? "documents"
    });

    this.fetchUser()
      .then(cToken => {
        if (!cToken) {
          window.location.href = '/';
        }
        const oToken = decodeToken(cToken);
        const { oUser } = oToken;
        oUser.oCustomerUser = oToken.oCustomerUser;
        oUser.cStorageToken = oToken.cStorageToken;
        signIn(oToken.cStorageToken, true, false)

        this.setState({
          bSyncLoading: false
        });

        this.setState({
          oUser,
          bLoading: false
        });
      })
      .catch(oError => { });
  }

  changeIsMenuOpened = () => {
    const { bIsMenuOpened } = this.state;

    if (bIsMenuOpened) {
      this.setState({ bIsMenuOpened: false, bIsTransitionEnd: false });
    } else {
      this.setState({ bIsMenuOpened: true });
    }
  };

  fetchUser = () => {
    const { nUserId } = this.state;

    return Axios.get(`/user-authenticate/${nUserId}`).then(oResponse => oResponse.data);
  };

  render() {
    const {
      history,
      cId,
      className,
      bCentered,
      children,
      rcmpBreadcrumb,
      cTitle,
      cImage,
      oFilterReport,
      cDescription,
      rcmpCardHeader,
      onChangeFilter,
      cCurrentSideMenuOption,
      bIsUserConfigMenu,
      loading
    } = this.props;
    const {
      oUser,
      bLoading,
      bIsMenuOpened,
      bIsTransitionEnd,
      bSyncLoading,
      currentTypeFilterShortcut,
      aSelectedUsers,
      aSelectedDocuments
    } = this.state;
  
    const { cFilterLabelUsers, cFilterLabelTypes,cFilterLabelDocument, aFilterUsers, aFilterTypes,nFilterStatDoc } = oFilterReport;
    const oClassName = classNames('v2-page', className, {
      centered: bCentered,
      'menu-expanded': bIsMenuOpened,
      'sidemenu-names': bIsTransitionEnd 
    });

    if (bSyncLoading) {
      return <></>;
    }

    const handleTypeFilterChange = (typeFilterValue) => {
      this.setState({ currentTypeFilterShortcut: typeFilterValue })

      onChangeFilter(aSelectedUsers, typeFilterValue);

      if (!cFilterLabelTypes) {
        return
      }

      localStorage.setItem('filter_type_report', typeFilterValue)
    }

    const handleChangeUsers = (elSelectedOption, cType = undefined) => {
      if (cType !== undefined) {
        const options = elSelectedOption.map(({ value }) => value);
        this.setState({
          [cType]: options
        });
      }
    };

    const handleChangeDocuments = (elSelectedOption) => {
      if (elSelectedOption) {
        const selectedDocument = elSelectedOption.value; // Obtém o valor selecionado
        this.setState(
          { aSelectedDocuments: [selectedDocument] }, // Atualiza o estado com o documento selecionado
          () => {
            onChangeFilter(aSelectedUsers, currentTypeFilterShortcut, [selectedDocument]); // Chama a função passada por props
          }
        );
      } else {
        // Caso não haja seleção, limpa o estado
        this.setState({ aSelectedDocuments: [] }, () => {
          onChangeFilter(aSelectedUsers, currentTypeFilterShortcut, []); // Chama a função passada por props
        });
      }
    };
   

    const handleOnChange = () => {
      localStorage.setItem(cFilterLabelUsers, JSON.stringify(aSelectedUsers))
      onChangeFilter(aSelectedUsers, currentTypeFilterShortcut);
    }

    return (
      <>
        <div
          className={oClassName}
          onTransitionEnd={() => {
            this.setState({
              bIsTransitionEnd: bIsMenuOpened
            });
          }}
        >
          <SideMenu
            cCurrentSideMenuOption={cCurrentSideMenuOption}
            bIsUserConfigMenu={bIsUserConfigMenu}
            oUser={oUser}
            bIsOpened={bIsMenuOpened}
            fnMenuOpenEvent={this.changeIsMenuOpened}
          />
          {bLoading || loading === 1 ? (
            <div className="centered-loading">
              <img alt={intl.get('loading')} src={cLoadingImage} />
            </div>
          ) : (

            <div className="v2-page-container">
              <Header
                history={history}
                cCurrentHeaderOption={cCurrentSideMenuOption}
                rcmpBreadcrumb={rcmpBreadcrumb}
                cTitle={cTitle}
                cImage={cImage}
                cDescription={cDescription}
                oUser={oUser}
              />
              <div className="v2-page-content">
                <div className="card v2-page-card">
                  {rcmpCardHeader}
                  <div className="grid-container">
                  <div className='dateFilterWrapper grid-item'
                      style={{
                        alignItems: "flex-start"
                      }}
                    >
                      <span
                        className={`dateFilterTitle dateFilterTitleWithShortCutCustom `}
                      >
                        {cFilterLabelTypes}
                      </span>
                      <div
                        className={`dateFiltersContainer ${currentTypeFilterShortcut !== "custom"
                            ? 'dataFilterContainterWithShortCutCustom'
                            : 'noPadding'}
                      `}
                      >
                        <div className='buttonsContainer'>
                          {aFilterTypes.map(({ label, value }) => (
                            <button
                              type="button"
                              title={label}
                              key={value}
                              className={`button ${value === currentTypeFilterShortcut && 'active'
                                }`}
                              onClick={() => handleTypeFilterChange(value)}
                            >
                              {label}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='dateFilterWrapper'
                      style={{
                        alignItems: "flex-start"
                      }}
                    >
                      <span
                        className={`dateFilterTitle dateFilterTitleWithShortCutCustom `}
                      >
                        {cFilterLabelUsers}
                      </span>

                      <div
                        className="dateFiltersContainer dataFilterContainterWithShortCutCustom"
                      >
                        <Select
                          className="selectCustom select"
                          classNamePrefix="react-select"
                          noOptionsMessage={() => intl.get('sem_opcoes')}
                          placeholder={intl.get('selecione')}
                          required
                          isClearable
                          isSearchable
                          isMulti
                          options={aFilterUsers}
                          maxMenuHeight={100}
                          defaultValue={aSelectedUsers}
                          onBlur={() => handleOnChange(aSelectedUsers, 'aSelectedUsers')}
                          onChange={elSelectedOption => handleChangeUsers(elSelectedOption, 'aSelectedUsers')}
                        />
                      </div>
                    </div>
                    <br/>
                    {currentTypeFilterShortcut === "documents" && (
                    <div className='dateFilterWrapper' style={{ alignItems: "flex-start" }}>
                      <span className={`dateFilterTitle dateFilterTitleWithShortCutCustom`}>
                        {cFilterLabelDocument}
                      </span>

                      <div>
                        <Select
                          className="selectCustomStatusDoc select"
                          classNamePrefix="react-select"
                          noOptionsMessage={() => intl.get('sem_opcoes')}
                          placeholder={intl.get('selecione')}
                          required
                          options={oFilterReport.aFilterDocuments}
                          maxMenuHeight={100}
                          value={oFilterReport.aFilterDocuments.find(
                            option => option.value === this.state.aSelectedDocuments[0]
                          )}
                          onChange={handleChangeDocuments}
                        />
                      </div>
                    </div>
                  )}
                  </div>
                  <div id={cId} className="card-body">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

ReportPage.defaultProps = {
  oFilterReport: {},
  className: '',
  cId: '',
  bCentered: false,
  children: [],
  rcmpBreadcrumb: '',
  cTitle: '',
  cDescription: '',
  rcmpCardHeader: '',
  cCurrentSideMenuOption: '',
  bIsUserConfigMenu: false,
  loading: 1,
  onChangeFilter: () => { },
};

export default withRouter(ReportPage);
