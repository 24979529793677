import React, { Component } from 'react';
import { Alert } from '../../components/Alert';
import intl from 'react-intl-universal';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';
import { Input } from 'reactstrap';
import AltCheckbox from '../../components/AltCheckbox';
import Page from '../default/Page';
import Axios from '../../config/Axios';
import { validarCNPJ, validarCPF } from '../../config/Validator';
import './styles/billingdata.scss';

class BillingData extends Component {
  DEFAULT_REGISTRY_TYPE = 0;
  CHANGE_REGISTRY_TYPE = 'change_registry_type';
  CHANGE_NATURAL_PERSON = 'change_natural_person';
  CHANGE_LEGAL_PERSON = 'change_legal_person';
  CHANGE_CONTACT = 'change_contact';
  CHANGE_ADDRESS = 'change_address';
  ZIP_CODE_KEYS = ['logradouro', 'localidade', 'siglaEstado', 'bairro'];

  constructor(oProps) {
    super(oProps);

    this.refAlertWrapper = React.createRef();

    this.state = {
      bIsLoading: false,
      bIsSending: false,
      cCustomerType: oProps.oCustomer.cust_ctype,
      oAddressAutomaticFilling: {
        bCity: oProps.oCustomer.cust_ccity?.length > 0,
        bState: oProps.oCustomer.cust_cstate?.length > 0,
        bStreet: oProps.oCustomer.cust_cstreet?.length > 0,
        bDistrict: oProps.oCustomer.cust_cdistrict?.length > 0
      },
      oAddressData: {
        cust_cpostalcode: oProps.oCustomer.cust_cpostalcode ?? '',
        cust_ccity: oProps.oCustomer.cust_ccity ?? '',
        cust_cstate: oProps.oCustomer.cust_cstate ?? '',
        cust_cstreet: oProps.oCustomer.cust_cstreet ?? '',
        cust_cdistrict: oProps.oCustomer.cust_cdistrict ?? '',
        cust_cnumber: oProps.oCustomer.cust_cnumber ?? '',
        cust_ccomplement: oProps.oCustomer.cust_ccomplement ?? ''
      },
      oContactData: {
        cust_cemail: oProps.oCustomerSubscription?.ca_cemail ?? '',
        cust_cphone: oProps.oCustomer.cust_cphone ?? ''
      },
      oLegalPersonData: {
        cust_cname: oProps.oCustomer.cust_cname ?? '',
        cust_cfantasyname: oProps.oCustomer.cust_cfantasyname ?? '',
        cust_ccnpj: oProps.oCustomer.cust_ccnpj ?? '',
        cust_cIE: oProps.oCustomer.cust_cIE ?? '',
        cust_cIM: oProps.oCustomer.cust_cIM,
        bIsSimple: oProps.oCustomer.cust_etaxframework === 'Optante',
        bIsPublicEntity: oProps.oCustomer.cust_etaxframework === 'Isento'
      },
      oNaturalPersonData: {
        cust_cname: oProps.oCustomer.cust_cname ?? '',
        cust_ccpf: oProps.oCustomer.cust_ccpf ?? ''
      },
      aRegistryTypeOptions: [
        {
          label: intl.get('BillingData.legal_person'),
          value: 'PJ'
        },
        {
          label: intl.get('BillingData.natural_person'),
          value: 'PF'
        }
      ],
      rcmpAlert: null
    };
  }

  saveBillingData = event => {
    event.preventDefault();
    const { cCustomerType, oLegalPersonData, oNaturalPersonData, oAddressData, oContactData } = this.state;

    let data = {};

    if (cCustomerType === 'PJ') {
      data = {
        ...oLegalPersonData,
        cust_etaxframework: oLegalPersonData.bIsSimple
          ? 'Optante'
          : oLegalPersonData.bIsPublicEntity
            ? 'Isento'
            : 'Não optante'
      };
    } else {
      data = {
        ...oNaturalPersonData,
        cust_cfantasyname: oNaturalPersonData.cust_cname
      };
    }
    data = {
      ...data,
      ...oAddressData,
      ...oContactData,
      cust_ctype: cCustomerType
    };
    this.props.onSuccess(data);
  };

  handleChange = (event, modifier) => {
    switch (modifier) {
      case this.CHANGE_REGISTRY_TYPE:
        this.setState({
          cCustomerType: event.value
        });
        break;
      case this.CHANGE_NATURAL_PERSON:
        const { oNaturalPersonData } = this.state;
        oNaturalPersonData[event.target.name] = event.target.value;
        this.setState({ oNaturalPersonData });
        break;
      case this.CHANGE_LEGAL_PERSON:
        const { oLegalPersonData } = this.state;
        oLegalPersonData[event.target.name] = event.target.value;
        this.setState({ oLegalPersonData });
      case this.CHANGE_ADDRESS:
        const { oAddressData } = this.state;
        oAddressData[event.target.name] = event.target.value;
        this.setState({ oAddressData });
        break;
      case this.CHANGE_CONTACT:
        const { oContactData } = this.state;
        oContactData[event.target.name] = event.target.value;
        this.setState({ oContactData });
        break;
      default:
        console.log('UNKNOWN MODIFIER');
    }
  };

  handleChangeCheckbox = event => {
    const { oLegalPersonData } = this.state;
    oLegalPersonData[event.target.name] = event.target.checked;

    if (event.target.name === 'bIsPublicEntity' && event.target.checked) {
      oLegalPersonData.bIsSimple = false;
    }

    this.setState({ oLegalPersonData });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  validateCPF = event => {
    if (event.target.value !== '' && !validarCPF(event.target.value)) {
      const { oNaturalPersonData } = this.state;
      oNaturalPersonData.cust_ccpf = '';
      this.setState(
        {
          oNaturalPersonData,
          rcmpAlert: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('BillingData.invalid_cpf')}
            </Alert>
          )
        },
        () => this.refAlertWrapper?.current?.scrollIntoView()
      );
    }
  };

  validateCNPJ = event => {
    if (event.target.value !== '' && !validarCNPJ(event.target.value)) {
      const { oLegalPersonData } = this.state;
      oLegalPersonData.cust_ccnpj = '';
      this.setState(
        {
          oLegalPersonData,
          rcmpAlert: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('BillingData.invalid_cnpj')}
            </Alert>
          )
        },
        () => this.refAlertWrapper?.current?.scrollIntoView()
      );
    }
  };

  searchCEP = event => {
    const { oAddressData, oAddressAutomaticFilling } = this.state;
    const cZipcode = event.target?.value?.replace('-', '');

    if (!cZipcode) {
      return;
    }

    Axios.get(`https://cep.acredite.se/cep/${cZipcode}/json`).then(oResponse => {
      this.ZIP_CODE_KEYS.forEach(cItem => {
        const value = cItem in oResponse.data.result ? oResponse.data.result[cItem] : '';
        switch (cItem) {
          case 'logradouro':
            oAddressData.cust_cstreet = value;
            oAddressAutomaticFilling.bStreet = value.length > 0;
            break;
          case 'localidade':
            oAddressData.cust_ccity = value;
            oAddressAutomaticFilling.bCity = value.length > 0;
            break;
          case 'siglaEstado':
            oAddressData.cust_cstate = value;
            oAddressAutomaticFilling.bState = value.length > 0;
            break;
          case 'bairro':
            oAddressData.cust_cdistrict = value;
            oAddressAutomaticFilling.bDistrict = value.length > 0;
            break;
          default:
            break;
        }
      });
      this.setState({ oAddressData, oAddressAutomaticFilling });
    });
  };

  renderNaturalPersonInputs = () => {
    const { oNaturalPersonData } = this.state;
    const changeModifier = this.CHANGE_NATURAL_PERSON;

    return (
      <div className="form-block">
        <label className="split3 colspan">
          {intl.get('BillingData.full_name')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cname"
            maxLength="100"
            value={oNaturalPersonData.cust_cname}
            required
            type="text"
            className="form-control"
          />
        </label>
        <label className="split3">
          {intl.get('BillingData.cpf')} <span className="required">*</span>
          <MaskedInput
            onBlur={this.validateCPF}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            onChange={event => this.handleChange(event, changeModifier)}
            render={(cRef, oProps) => (
              <Input
                innerRef={cRef}
                {...oProps}
                value={oNaturalPersonData.cust_ccpf}
                type="text"
                name="cust_ccpf"
                id="cust_ccpf"
                className="mascara-cpf"
                required
                placeholder={intl.get('BillingData.placeholder_CPF')}
              />
            )}
          />
        </label>
        <hr />
      </div>
    );
  };

  renderLegalPersonInputs = () => {
    const { oLegalPersonData } = this.state;
    const changeModifier = this.CHANGE_LEGAL_PERSON;

    return (
      <div className="form-block">
        <label>
          {intl.get('BillingData.corporate_name')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cname"
            maxLength="100"
            value={oLegalPersonData.cust_cname}
            required
            type="text"
            className="form-control"
          />
        </label>
        <label className="split2">
          {intl.get('BillingData.trade_name')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cfantasyname"
            maxLength="100"
            value={oLegalPersonData.cust_cfantasyname}
            required
            type="text"
            className="form-control"
          />
        </label>
        <label className="split2">
          {intl.get('BillingData.cnpj')} <span className="required">*</span>
          <MaskedInput
            onBlur={this.validateCNPJ}
            mask={[
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '.',
              /\d/,
              /\d/,
              /\d/,
              '/',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/
            ]}
            onChange={event => this.handleChange(event, changeModifier)}
            render={(cRef, oProps) => (
              <Input
                innerRef={cRef}
                {...oProps}
                value={oLegalPersonData.cust_ccnpj}
                type="text"
                name="cust_ccnpj"
                id="cust_ccnpj"
                className="mascara-cnpj"
                required
                placeholder={intl.get('BillingData.placeholder_CNPJ')}
              />
            )}
          />
        </label>
        <label className="split2">
          {intl.get('BillingData.state_registration')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cIE"
            maxLength="100"
            value={oLegalPersonData.cust_cIE}
            required
            type="text"
            className="form-control"
          />
        </label>
        <label className="split2">
          {intl.get('BillingData.city_registration')}
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cIM"
            maxLength="100"
            value={oLegalPersonData.cust_cIM}
            type="text"
            className="form-control"
          />
        </label>
        <AltCheckbox
          onChange={event => this.handleChangeCheckbox(event)}
          title={intl.get('BillingData.is_simple')}
          label={intl.get('BillingData.is_simple')}
          name="bIsSimple"
          localeLabel="start"
          className={`split2 billing-data-alt-checkbox${oLegalPersonData.bIsPublicEntity ? ' disabled' : ''}`}
          disabled={oLegalPersonData.bIsPublicEntity}
          checked={oLegalPersonData.bIsSimple}
        />
        <AltCheckbox
          onChange={event => this.handleChangeCheckbox(event)}
          title={intl.get('BillingData.public_entity')}
          label={intl.get('BillingData.public_entity')}
          name="bIsPublicEntity"
          localeLabel="start"
          className="split2 billing-data-alt-checkbox"
          checked={oLegalPersonData.bIsPublicEntity}
        />
        <hr />
      </div>
    );
  };

  renderAddressInputs = () => {
    const { oAddressData, oAddressAutomaticFilling } = this.state;
    const changeModifier = this.CHANGE_ADDRESS;

    return (
      <div className="form-block">
        <label className="split3">
          {intl.get('BillingData.cep')} <span className="required">*</span>
          <MaskedInput
            onBlur={this.searchCEP}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            onChange={event => this.handleChange(event, changeModifier)}
            render={(cRef, oProps) => (
              <Input
                innerRef={cRef}
                {...oProps}
                value={oAddressData.cust_cpostalcode}
                type="text"
                name="cust_cpostalcode"
                id="cust_cpostalcode"
                className="mascara-cep"
                required
                placeholder={intl.get('BillingData.placeholder_CEP')}
              />
            )}
          />
        </label>
        <label className="split3">
          {intl.get('BillingData.city')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_ccity"
            maxLength="100"
            value={oAddressData.cust_ccity}
            required
            type="text"
            className="form-control"
            disabled={oAddressAutomaticFilling.bCity}
          />
        </label>
        <label className="split3">
          {intl.get('BillingData.state')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cstate"
            maxLength="2"
            value={oAddressData.cust_cstate}
            required
            type="text"
            className="form-control"
            disabled={oAddressAutomaticFilling.bState}
          />
        </label>
        <label className="split3 colspan">
          {intl.get('BillingData.street')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cstreet"
            maxLength="100"
            value={oAddressData.cust_cstreet}
            required
            type="text"
            className="form-control"
            disabled={oAddressAutomaticFilling.bStreet}
          />
        </label>
        <label className="split3">
          {intl.get('BillingData.district')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cdistrict"
            maxLength="100"
            value={oAddressData.cust_cdistrict}
            required
            type="text"
            className="form-control"
            disabled={oAddressAutomaticFilling.bDistrict}
          />
        </label>
        <label className="split3">
          {intl.get('BillingData.number')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cnumber"
            maxLength="100"
            value={oAddressData.cust_cnumber}
            required
            type="text"
            className="form-control"
          />
        </label>
        <label className="split3 colspan">
          {intl.get('BillingData.complement')}
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_ccomplement"
            maxLength="100"
            value={oAddressData.cust_ccomplement}
            type="text"
            className="form-control"
          />
        </label>
        <hr />
      </div>
    );
  };

  renderContactInputs = () => {
    const { oContactData } = this.state;
    const changeModifier = this.CHANGE_CONTACT;

    return (
      <div className="form-block">
        <label className="split3 colspan">
          {intl.get('BillingData.email')} <span className="required">*</span>
          <input
            onChange={event => this.handleChange(event, changeModifier)}
            name="cust_cemail"
            maxLength="100"
            value={oContactData.cust_cemail}
            required
            type="email"
            placeholder={intl.get('BillingData.placeholder_email')}
            className="form-control"
          />
        </label>
        <label className="split3">
          {intl.get('BillingData.phone')} <span className="required">*</span>
          <MaskedInput
            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            onChange={event => this.handleChange(event, changeModifier)}
            render={(cRef, oProps) => (
              <Input
                innerRef={cRef}
                {...oProps}
                value={oContactData.cust_cphone}
                type="text"
                name="cust_cphone"
                id="cust_cphone"
                className="mascara-telefone"
                required
                placeholder={intl.get('BillingData.placeholder_phone')}
              />
            )}
          />
        </label>
      </div>
    );
  };

  render() {
    const { bIsLoading, bIsSending, cCustomerType, aRegistryTypeOptions, rcmpAlert } = this.state;
    const { closeModal } = this.props;

    return (
      <Page loading={bIsLoading ? 1 : 0}>
        <div ref={this.refAlertWrapper} className="alert-container">
          {rcmpAlert}
        </div>

        <form className="form" onSubmit={event => this.saveBillingData(event)}>
          <label className="split2 colspan ">
            {intl.get('BillingData.registry_type')}
            <span className="required">*</span>
            <Select
              name="cRegistryType"
              className="selectCustom"
              isSearchable={false}
              classNamePrefix="react-select"
              placeholder={intl.get('selecione')}
              options={aRegistryTypeOptions}
              defaultValue={cCustomerType === 'PJ' ? aRegistryTypeOptions[0] : aRegistryTypeOptions[1]}
              noOptionsMessage={() => intl.get('sem_opcoes')}
              onChange={oSelectedOption => this.handleChange(oSelectedOption, this.CHANGE_REGISTRY_TYPE)}
            />
          </label>

          {cCustomerType === 'PF' && this.renderNaturalPersonInputs()}

          {cCustomerType === 'PJ' && this.renderLegalPersonInputs()}

          {this.renderAddressInputs()}

          {this.renderContactInputs()}

          <div className="button-stack">
            <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
              {intl.get('cancelar')}
            </button>
            <button
              disabled={bIsSending}
              type="submit"
              title={intl.get('salvar')}
              className={`btn btn btn-secondary ${bIsSending ? 'disabled' : ''}`}
            >
              {intl.get('salvar')}
            </button>
          </div>
        </form>
      </Page>
    );
  }
}

export default BillingData;
